import { Divider, Icon, IconButton, SwipeableDrawer } from '@mui/material';
import React from 'react';
import SearchFilters from '../SearchResults/SearchFilters';
import { useSearchParams } from 'react-router-dom';
import { Cross1Icon } from '@radix-ui/react-icons';

const SearchFiltersDrawer = ({
  onSubmit,
  isFiltersDrawerOpen,
  setIsFiltersDrawerOpen,
  handleCloseFiltersDrawer,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div className=''>
      <SwipeableDrawer
        onOpen={() => setIsFiltersDrawerOpen(true)}
        open={isFiltersDrawerOpen}
        anchor='right'
        onClose={handleCloseFiltersDrawer}
        variant='temporary'
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <div className='flex flex-col w-screen md:w-80 h-full'>
          {/* First Div: Fixed Height */}
          <div className='flex justify-between items-center p-4 h-16'>
            <h1 className='text-base font-bold md:text-lg lg:text-xl select-none'>
              Filtros
            </h1>
            <div className='bg-slate-300 p-1 px-4 rounded-md shadow-sm select-none'>
              <h1 className='text-sm font-semibold'>
                {searchParams.get('action') === 'sale' ? 'Comprar' : 'Alugar'}
              </h1>
            </div>
            <div>
              <IconButton onClick={handleCloseFiltersDrawer}>
                <Cross1Icon />
              </IconButton>
            </div>
          </div>
          <Divider />
          {/* Second Div: Scrollable */}
          <div className='flex-grow overflow-y-auto py-4'>
            <SearchFilters onSubmit={onSubmit} />
          </div>
          {/* Third Div: Fixed Height */}
          {/* <div className='flex justify-center items-center h-24 p-4'>
            <button
              type='submit'
              form='search-filters-form'
              className='w-full h-10 md:h-12 px-2 text-sm text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg'
            >
              Buscar imóveis
            </button>
          </div> */}
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default SearchFiltersDrawer;
