import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import tuneIcon from '../../media/icons/tuneIcon.svg';
import bellIcon from '../../media/icons/bellIcon.svg';
import polygonIcon from '../../media/icons/polygonIcon.svg';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import alertAnimationLottie from '../../animations/notification.json';
import filterAnimationLottie from '../../animations/Filter.json';
import drawAnimationLottie from '../../animations/Rectangle.json';

const SearchResultsMapButtons = ({
  isFiltersDrawerOpen,
  isCreateAlertDialogOpen,
  isClearMapDialogOpen,
  setIsFiltersDrawerOpen,
  setIsCreateAlertDialogOpen,
  setIsClearMapDialogOpen,
}) => {
  const [filtersAnimation, setFiltersAnimation] = useState(false);
  const [alertAnimation, setAlertAnimation] = useState(false);
  const [drawAnimation, setDrawAnimation] = useState(false);

  const { selectedProperty } = useSelector((state) => state.propertyValues);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get('action')) {
      setSearchParams((old) => {
        old.set('action', 'rent');
        return old;
      });
    }
  }, [searchParams]);

  const handleActionChange = (action) => {
    setSearchParams((old) => {
      old.set('action', action);
      return old;
    });
  };

  return (
    <div
      className={`flex flex-col rounded-md  items-center justify-center gap-1 `}
    >
      <div className='flex flex-col gap-2 p-2 w-28 bg-white rounded-md shadow-inner border border-gray-600 text-xs font-semibold'>
        <button
          className={`px-2 py-[2px] w-full rounded-lg border border-transparent hover:border-black shadow-md ${
            searchParams.get('action') === 'rent'
              ? 'bg-emerald-600 bg-opacity-80'
              : 'bg-slate-300'
          }`}
          onClick={() => handleActionChange('rent')}
        >
          Alugar
        </button>
        <button
          className={`px-2 py-[2px] w-full rounded-lg border border-transparent hover:border-black shadow-md ${
            searchParams.get('action') === 'sale'
              ? 'bg-emerald-600 bg-opacity-60'
              : 'bg-slate-300'
          }`}
          onClick={() => handleActionChange('sale')}
        >
          Comprar
        </button>
      </div>
      <div className='flex'>
        <button
          className='flex items-center gap-2 p-2 w-28 bg-slate-50 border border-gray-600 hover:bg-emerald-600 duration-500 rounded-md shadow-xl text-xs  font-semibold'
          onClick={() => setIsFiltersDrawerOpen(true)}
          onMouseEnter={() => setFiltersAnimation(true)}
          onMouseLeave={() => setFiltersAnimation(false)}
        >
          <div className='w-6'>
            <Lottie
              options={{
                autoplay: false,
                loop: true,
                animationData: filterAnimationLottie,
              }}
              height={20}
              width={20}
              isStopped={!filtersAnimation}
            />
          </div>
          Filtros
        </button>
      </div>
      <div>
        <button
          className='flex items-center gap-2 p-2 w-28 bg-slate-50 border border-gray-600 hover:bg-emerald-600 duration-500 rounded-md shadow-xl text-xs  font-semibold'
          onClick={() => setIsCreateAlertDialogOpen(true)}
          onMouseEnter={() => setAlertAnimation(true)}
          onMouseLeave={() => setAlertAnimation(false)}
        >
          {/* <img src={bellIcon} className='w-4 h-4' /> */}
          <div className='w-6'>
            <Lottie
              options={{
                autoplay: false,
                loop: true,
                animationData: alertAnimationLottie,
              }}
              height={20}
              width={20}
              isStopped={!alertAnimation}
            />
          </div>
          Criar Alerta
        </button>
      </div>
      <div>
        <button
          onClick={() => navigate('/desenhar')}
          className='flex items-center gap-2 p-2 w-28 bg-slate-50 border border-gray-600 hover:bg-emerald-600 duration-500 rounded-md shadow-xl text-xs  font-semibold'
          onMouseEnter={() => setDrawAnimation(true)}
          onMouseLeave={() => setDrawAnimation(false)}
        >
          <div className='w-6'>
            <Lottie
              options={{
                autoplay: false,
                loop: true,
                animationData: drawAnimationLottie,
              }}
              height={20}
              width={20}
              isStopped={!drawAnimation}
            />
          </div>
          Desenhar
        </button>
      </div>
    </div>
  );
};

export default SearchResultsMapButtons;
