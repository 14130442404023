import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  selectedSearchResult: null,
  drawnAreaId: null,
};

const objectValuesSlice = createSlice({
  name: 'objectValues',
  initialState: initialFormState,
  reducers: {
    setSelectedSearchResult(state, action) {
      state.selectedSearchResult = action.payload;
    },
    setDrawnAreaId(state, action) {
      state.drawnAreaId = action.payload;
    },
    resetObjectValues(state) {
      state.selectedSearchResult = null;
      state.drawnAreaId = null;
    },
  },
});

export const objectValuesActions = objectValuesSlice.actions;

export default objectValuesSlice.reducer;
