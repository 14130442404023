import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useClickAway, useDebounce, useMediaQuery } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { searchAddress } from '../../services/mapService';
import { mutate } from 'swr';
import { formatResults } from '../../helpers/formattingFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { objectValuesActions } from '../../store/objectValues';
import { propertyValuesActions } from '../../store/propertyValues';
import {
  useGetPropertiesByGeometry,
  useGetPropertiesByMapBounds,
} from '../../services/propertyService';
import { useSearchParams } from 'react-router-dom';
import { MapsHomeWork } from '@mui/icons-material';
import Lottie from 'react-lottie';
import deleteAnimation from '../../animations/delete.json';
import ClearMapDialog from '../Dialogs/ClearMapDialog';
import L from 'leaflet';
import loadingLottieWhite from '../../animations/loading-white.json';
import loadingLottieGreen from '../../animations/loading-green.json';

const Autocomplete = ({
  isFiltersDrawerOpen,
  isClearMapDialogOpen,
  isCreateAlertDialogOpen,
  setIsClearMapDialogOpen,
  map,
}) => {
  const [searchText, setSearchText] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [deleteAnimationPaused, setDeleteAnimationPaused] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { propertiesCounter, selectedProperty } = useSelector(
    (state) => state.propertyValues
  );
  const { menuDrawerOpen, loginDrawerOpen } = useSelector(
    (state) => state.drawerValues
  );
  const { mapZoom, mapBounds } = useSelector((state) => state.mapValues);
  const { selectedSearchResult } = useSelector((state) => state.objectValues);

  const filters = {};
  searchParams.entries().forEach((item) => {
    filters[item[0]] = item[1];
  });

  const isExtraSmallDevice = useMediaQuery(
    'only screen and (max-width : 450px)'
  );

  const clearLayers = () => {
    map.eachLayer((layer) => {
      if (
        layer instanceof L.Circle ||
        layer instanceof L.Polyline ||
        layer instanceof L.Polygon ||
        layer instanceof L.Marker ||
        layer instanceof L.MarkerClusterGroup
      ) {
        map.removeLayer(layer);
      }
    });
  };

  const {
    propertiesByGeometry,
    isLoadingPropertiesByGeometry,
    isErrorPropertiesByGeometry,
    isValidatingPropertiesByGeometry,
  } = useGetPropertiesByGeometry(
    selectedSearchResult,
    filters,
    filters['action'] || 'rent',
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const {
    propertiesByMapBounds,
    isLoadingPropertiesByMapBounds,
    isErrorPropertiesByMapBounds,
    isValidatingPropertiesByMapBounds,
  } = useGetPropertiesByMapBounds(
    mapZoom,
    mapBounds,
    selectedSearchResult,
    filters,
    filters['action'] || 'rent',
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 2000,
    }
  );

  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText.length > 2) {
      setIsLoading(true);
      searchAddress(debouncedSearchText)
        .then((data) => {
          setSearchResults(data);
          setShowResults(true);
          console.log('Search Results:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [debouncedSearchText]);

  const handleInputChange = (e) => {
    if (e.target.value === '') {
      dispatch(objectValuesActions.setSelectedSearchResult(null));
      dispatch(propertyValuesActions.setPropertiesCounter(0));
      setSearchResults(null);
      mutate('searchAddress', null);
    }
    setSearchText(e.target.value);
  };

  const handleClearButtonClick = () => {
    setSearchText('');
    setSearchResults(null);
    mutate('searchAddress', null);
    dispatch(objectValuesActions.setSelectedSearchResult(null));
    dispatch(propertyValuesActions.setPropertiesCounter(0));
  };

  const handleFocus = () => {
    if (searchResults) {
      console.log('Focus');
      setShowResults((old) => !old);
    }
  };

  const handleSelectedResult = (result) => {
    dispatch(objectValuesActions.setSelectedSearchResult(result));
    setShowResults(false);
  };

  const handleClearMap = () => {
    dispatch(objectValuesActions.resetObjectValues());
    dispatch(objectValuesActions.setSelectedSearchResult(null));
    dispatch(propertyValuesActions.setPropertiesCounter(0));
    clearLayers();
    mutate('properties');
    setIsClearMapDialogOpen(false);
    setSearchText('');
    setSearchResults(null);
    mutate('searchAddress', null);
    dispatch(objectValuesActions.setSelectedSearchResult(null));
    dispatch(propertyValuesActions.setPropertiesCounter(0));
  };

  const resultsRef = useClickAway(() => {
    console.log('Click Away');
    setShowResults(false);
  });

  return (
    <div className='flex flex-col w-full px-2 md:w-[480px] absolute transform -translate-x-1/2 left-1/2 top-[75px] z-[999]'>
      <ClearMapDialog
        isClearMapDialogOpen={isClearMapDialogOpen}
        setIsClearMapDialogOpen={setIsClearMapDialogOpen}
        clearLayers={clearLayers}
        handleClearMap={handleClearMap}
      />
      <div
        className='border border-gray-600 rounded-lg focus:outline-emerald-600 w-full flex bg-white items-center justify-center'
        onClick={handleFocus}
      >
        <input
          className='flex-grow p-2 text-ellipsis focus:outline-none rounded-lg border-gray-300 pr-2'
          type='text'
          placeholder='Rua, bairro, localidades...'
          value={searchText}
          onChange={handleInputChange}
        />
        <div className='flex items-center'>
          {isLoading && (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingLottieGreen,
              }}
              height={22}
              width={22}
              speed={4}
            />
          )}
        </div>
        <div
          className={`${
            selectedProperty ? 'hidden' : ''
          } flex animate-flash cursor-default items-center justify-center gap-2 p-1 px-4 mx-2 rounded-md  text-xs font-bold duration-300 z-[999] bg-emerald-500 text-white`}
        >
          {isLoadingPropertiesByGeometry ||
          isLoadingPropertiesByMapBounds ||
          isValidatingPropertiesByMapBounds ||
          isValidatingPropertiesByGeometry ? (
            <div className='flex items-center justify-center gap-2'>
              {isExtraSmallDevice ? (
                <MapsHomeWork
                  sx={{ color: 'white', width: '16px', height: '16px' }}
                />
              ) : (
                <h2 className='text-md'>Buscando:</h2>
              )}
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loadingLottieWhite,
                }}
                height={14}
                width={14}
              />
            </div>
          ) : (
            <div
              className={`${
                selectedProperty ||
                isFiltersDrawerOpen ||
                isCreateAlertDialogOpen ||
                isClearMapDialogOpen ||
                menuDrawerOpen ||
                loginDrawerOpen
                  ? 'hidden'
                  : ''
              } flex items-center justify-center  text-xs  duration-300 z-[999]`}
            >
              <Tooltip
                title='Imóveis encontrados'
                placement='bottom'
                arrow
                enterDelay={100}
              >
                <div className='flex items-center justify-center gap-2'>
                  {isExtraSmallDevice ? (
                    <MapsHomeWork
                      sx={{ color: 'white', width: '16px', height: '16px' }}
                    />
                  ) : (
                    <h2 className='text-md'>Imóveis:</h2>
                  )}
                  <h2 className='text-md'>
                    {new Intl.NumberFormat().format(propertiesCounter)}
                  </h2>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className={`${
            selectedProperty ||
            isFiltersDrawerOpen ||
            isCreateAlertDialogOpen ||
            isClearMapDialogOpen ||
            menuDrawerOpen ||
            loginDrawerOpen
              ? 'hidden'
              : ''
          } flex items-center justify-center w-10 h-8 text-xs  font-semibold duration-300 z-[999]`}
        >
          <Tooltip title='Limpar Busca' placement='right' arrow>
            <button
              className='w-6 h-6 '
              onClick={() => setIsClearMapDialogOpen(true)}
              onMouseEnter={() => setDeleteAnimationPaused(false)}
              onMouseLeave={() => setDeleteAnimationPaused(true)}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: false,
                  animationData: deleteAnimation,
                }}
                height={20}
                width={20}
                isStopped={deleteAnimationPaused}
              />
            </button>
          </Tooltip>
        </div>
      </div>
      {showResults && searchText && searchResults && !searchResults.message && (
        <div
          className='w-full bg-white border border-gray-300 rounded-lg mt-0.5 max-h-80 overflow-y-auto z-[999]'
          ref={resultsRef}
        >
          {searchResults?.map((result) => (
            <div
              key={result.properties.geocoding.place_id}
              className='p-2 hover:bg-gray-100 cursor-pointer'
              onClick={() => handleSelectedResult(result)}
            >
              <h1 className='text-sm'>{formatResults(result)}</h1>
            </div>
          ))}
          {searchResults?.length === 0 && (
            <div className='p-2 text-center'>Nenhum resultado encontrado</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
