import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { objectValuesActions } from '../../store/objectValues';
import { useDispatch } from 'react-redux';
import { mapValuesActions } from '../../store/mapValues';
import { mutate } from 'swr';
import { propertyValuesActions } from '../../store/propertyValues';

const ClearMapDialog = ({
  isClearMapDialogOpen,
  setIsClearMapDialogOpen,
  handleClearMap,
}) => {
  return (
    <Dialog
      open={isClearMapDialogOpen}
      onClose={() => setIsClearMapDialogOpen(false)}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>Limpar resultados</DialogTitle>
      <DialogContent dividers={true}>
        <div className='flex flex-col gap-3'>
          <p>Tem certeza que deseja limpar os resultados da busca?</p>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
          onClick={() => setIsClearMapDialogOpen(false)}
        >
          Cancelar
        </button>
        <button
          className='bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded'
          onClick={handleClearMap}
        >
          Limpar
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ClearMapDialog;
