import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  selectedProperty: null,
  selectedPropertyId: null,
  propertiesCounter: 0,
};

const propertyValuesSlice = createSlice({
  name: 'propertyValues',
  initialState: initialFormState,
  reducers: {
    setSelectedProperty(state, action) {
      state.selectedProperty = action.payload;
    },
    setSelectedPropertyId(state, action) {
      state.selectedPropertyId = action.payload;
    },
    setPropertiesCounter(state, action) {
      state.propertiesCounter = action.payload;
    },
  },
});

export const propertyValuesActions = propertyValuesSlice.actions;

export default propertyValuesSlice.reducer;
