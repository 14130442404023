import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import {
  drawingButtonsClassName,
  drawButtonClassName,
  removeButtonClassName,
  searchButtonClassName,
} from '../../utils/buttonsClasses';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { drawingPolygonConfig } from '../../utils/shapes';
import * as turf from '@turf/turf';
import { objectValuesActions } from '../../store/objectValues';
import { toast } from 'react-toastify';
import { useDrawnPolygonPropertiesCounter } from '../../services/propertyService';
import { mutate } from 'swr';
import { mapValuesActions } from '../../store/mapValues';

const DrawingButtonsDiv = ({ map, polygonHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedSearchResult, drawnAreaId } = useSelector(
    (state) => state.objectValues
  );

  const { mapBounds, mapZoom } = useSelector((state) => state.mapValues);

  const customToast = ({ closeToast }) => {
    return (
      <div className='flex flex-col gap-2'>
        <h1 className='text-md font-bold'>Imóveis encontrados:</h1>
        <div className='flex gap-2'>
          <h1 className='text-md font-base '>Alugar:</h1>
          <h1 className='text-md font-bold text-emerald-700'>
            {drawnPolygonPropertiesCounter?.propertiesToRent}
          </h1>
        </div>
        <div className='flex gap-2'>
          <h1 className='text-md font-base'>Comprar:</h1>
          <h1 className='text-md font-bold text-emerald-700'>
            {drawnPolygonPropertiesCounter?.propertiesToSell}
          </h1>
        </div>
      </div>
    );
  };

  let drawingButtonsControl = null;
  let editableLayers = null;
  const drawingButtonsDiv = L.DomUtil.create('div');
  const drawButton = L.DomUtil.create('button');
  const removeButton = L.DomUtil.create('button');
  const searchButton = L.DomUtil.create('button');

  drawButton.innerHTML = 'Desenhar';
  removeButton.innerHTML = 'Limpar';
  searchButton.innerHTML = 'Buscar';
  drawButton.className = drawButtonClassName;
  drawButton.id = 'drawButton';
  removeButton.className = removeButtonClassName;
  removeButton.id = 'removeButton';

  removeButton.disabled = true;
  searchButton.disabled = true;

  searchButton.className = searchButtonClassName;
  searchButton.id = 'searchButton';
  drawingButtonsDiv.className = drawingButtonsClassName;

  const { drawnPolygonPropertiesCounter, isLoading, isError, isValidating } =
    useDrawnPolygonPropertiesCounter(selectedSearchResult, {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    });

  const handleSearchClick = () => {
    navigate('/buscar');
  };

  const handleDrawClick = (e) => {
    console.log('Draw button clicked:', e);
    // start drawing mode

    map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.remove();
      }
    });

    removeButton.disabled = false;

    polygonHandler.enable();

    // console.log('Drawing mode enabled:', drawingButtonsControl);
  };

  const handleRemoveClick = (e) => {
    polygonHandler.disable();
    map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.editing.disable();
        map.removeLayer(layer);
      }
      drawButton.disabled = false;
      removeButton.disabled = true;
      searchButton.disabled = true;
    });

    dispatch(objectValuesActions.setDrawnAreaId(null));
  };

  const handleDrawCreated = (e) => {
    console.log('Draw created:', e);
    const geoJson = L.geoJSON(e.layer.toGeoJSON(), {
      style: {
        color: '#10B981',
        fill: true,
      },
      onEachFeature: (feature, layer) => {
        layer.editing.enable();
      },
    });
    console.log('GeoJson:', geoJson);
    geoJson.addTo(map);
    map.flyToBounds(geoJson.getBounds(), {
      padding: [100, 100],
      animate: false,
    });

    searchButton.disabled = false;
    drawButton.disabled = true;
    removeButton.disabled = false;

    dispatch(objectValuesActions.setDrawnAreaId(null));
    dispatch(objectValuesActions.setSelectedSearchResult(e.layer.toGeoJSON()));
  };

  const handleDrawEditVertex = (e) => {
    console.log('Draw edit vertex:', e);
    console.log('Draw edit vertex layer:', e.poly.intersects());
    console.log('Draw edit vertex layer:', e.poly.toGeoJSON());

    if (e.poly.intersects()) {
      e.poly.setStyle({
        color: '#FF0000',
        weight: 3,
        opacity: 1,
        fill: true,
        fillColor: '#FF0000',
        fillOpacity: 0.5,
        clickable: true,
      });
      searchButton.disabled = true;
      e.poly.bindTooltip('As linhas não podem se cruzar!', {
        permanent: true,
        direction: 'center',
        sticky: true,
      });
    } else {
      e.poly.setStyle({
        color: '#10B981', // color of the original polygon
        weight: 3,
        opacity: 1,
        fill: true,
        fillColor: '#10B981', // color of the original polygon
        fillOpacity: 0.5,
        clickable: true,
      });
      e.poly.unbindTooltip();
      searchButton.disabled = false;
      dispatch(objectValuesActions.setDrawnAreaId(null));
      dispatch(objectValuesActions.setSelectedSearchResult(e.poly.toGeoJSON()));
    }
  };
  useEffect(() => {
    const clearLayers = () => {
      map.eachLayer((layer) => {
        if (
          layer instanceof L.Circle ||
          layer instanceof L.Polyline ||
          layer instanceof L.Polygon ||
          layer instanceof L.Marker ||
          layer instanceof L.MarkerClusterGroup
        ) {
          map.removeLayer(layer);
        }
      });
    };
    map.whenReady(() => {
      map.on('moveend', () => {
        dispatch(mapValuesActions.setMapZoom(map.getZoom()));
        dispatch(
          mapValuesActions.setMapBounds([
            [
              map.getBounds().getSouthWest().lng,
              map.getBounds().getSouthWest().lat,
            ],
            [
              map.getBounds().getNorthEast().lng,
              map.getBounds().getNorthEast().lat,
            ],
          ])
        );
      });
      clearLayers();
      if (drawingButtonsControl instanceof L.Control) {
        drawingButtonsControl.remove();
      }

      if (!selectedSearchResult && mapBounds) {
        map.flyToBounds(
          mapBounds.map((bound) => [bound[1], bound[0]]),
          { animate: false }
        );
        map.setZoom(mapZoom);
      }

      if (
        selectedSearchResult &&
        selectedSearchResult.geometry.type !== 'Polygon'
      ) {
        toast.error(
          'Esta área não pode ser editada, crie uma nova ou selecione uma área salva',
          {
            position: 'bottom-center',
            closeButton: true,
            progressStyle: {
              background: '#EF4444',
            },
          }
        );
      }

      if (
        selectedSearchResult &&
        selectedSearchResult.geometry.type === 'Polygon'
      ) {
        const turfPolygon = turf.polygon(
          selectedSearchResult.geometry.coordinates
        );
        console.log('Turf polygon:', turfPolygon);
        let smoothedPolygon;

        if (selectedSearchResult.geometry.coordinates[0].length < 50) {
          console.log('Menor que 50');
          smoothedPolygon = selectedSearchResult;
        } else if (selectedSearchResult.geometry.coordinates[0].length < 500) {
          smoothedPolygon = turf.simplify(turfPolygon, {
            tolerance: 0.0001,
            highQuality: true,
          });
        } else if (selectedSearchResult.geometry.coordinates[0].length > 500) {
          smoothedPolygon = turf.simplify(turfPolygon, {
            tolerance: 0.01,
            highQuality: true,
          });
        }

        console.log('Smoothed polygon:', smoothedPolygon);

        const geoJson = L.geoJson(smoothedPolygon, {
          style: {
            color: '#10B981',
            fill: true,
          },
          onEachFeature: (feature, layer) => {
            layer.editing.enable();
          },
        });
        geoJson.addTo(map);
        map.flyToBounds(geoJson.getBounds(), {
          padding: [50, 50],
          animate: false,
        });
        searchButton.disabled = false;
        drawButton.disabled = true;
        removeButton.disabled = false;

        dispatch(objectValuesActions.setSelectedSearchResult(smoothedPolygon));
      }

      L.Control.DrawingButtons = L.Control.extend({
        onAdd: function (map) {
          drawButton.innerHTML = 'Desenhar';
          drawButton.className = drawButtonClassName;
          drawButton.id = 'drawButton';
          drawingButtonsDiv.className = drawingButtonsClassName;
          drawingButtonsDiv.appendChild(drawButton);
          drawingButtonsDiv.appendChild(removeButton);
          drawingButtonsDiv.appendChild(searchButton);
          L.DomEvent.on(drawButton, 'click', handleDrawClick);
          L.DomEvent.on(removeButton, 'click', handleRemoveClick);
          L.DomEvent.on(searchButton, 'click', handleSearchClick);
          // disable the drawing button when the drawing is finished and exits the drawing mode, but dont remove the control
          map.on('draw:created', handleDrawCreated);
          map.on('draw:editvertex', handleDrawEditVertex);
          map.on('draw:drawvertex', (e) => {
            console.log('Draw vertex:', e);
            map.eachLayer((layer) => {
              if (layer instanceof L.Polygon) {
                layer.remove();
              }
            });
          });

          return drawingButtonsDiv;
        },
        onRemove: function (map) {
          // Nothing to do here
          console.log('Map removed:', map);
          map.off('draw:created', handleDrawCreated);
          map.off('draw:editvertex', handleDrawEditVertex);
          L.DomEvent.off(drawButton, 'click', handleDrawClick);
          L.DomEvent.off(removeButton, 'click', handleRemoveClick);
          L.DomEvent.off(searchButton, 'click', handleSearchClick);
          // remove the control from the map so it can be added again on re-render
        },
      });
      drawingButtonsControl = new L.Control.DrawingButtons({
        position: 'bottomright',
        draw: {
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
          },
        },
        edit: {
          poly: {
            showArea: true,
            drawError: {
              color: '#10B981',
              message: '<strong>As linhas não podem se cruzar!</strong>',
            },
          },
          edit: false,
          remove: true,
          featureGroup: editableLayers,
        },
      });
      drawingButtonsControl.addTo(map);
    });

    return () => {
      map.removeControl(drawingButtonsControl);
    };
  }, [map]);

  useEffect(() => {
    if (selectedSearchResult) {
      mutate('drawnPolygonPropertiesCounter');
    }
  }, [selectedSearchResult]);

  useEffect(() => {
    if (
      drawnPolygonPropertiesCounter &&
      !isLoading &&
      !isError &&
      selectedSearchResult
    ) {
      toast(customToast, {
        className: 'border boder-emerald-700',
        position: 'top-center',
        closeButton: true,
        progressStyle: {
          background: '#10B981',
        },
      });
    }
  }, [drawnPolygonPropertiesCounter, isLoading, isError]);

  return null;
};

export default DrawingButtonsDiv;
