export const hasVisitedProperty = (propertyId) => {
  const visitedProperties =
    JSON.parse(localStorage.getItem('visitedProperties')) || [];
  return visitedProperties.includes(propertyId);
};

export const markPropertyAsVisited = (propertyId) => {
  const visitedProperties =
    JSON.parse(localStorage.getItem('visitedProperties')) || [];
  if (!visitedProperties.includes(propertyId)) {
    visitedProperties.push(propertyId);
    localStorage.setItem(
      'visitedProperties',
      JSON.stringify(visitedProperties)
    );
  }
};
