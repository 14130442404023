import React from 'react';
import { useNavigate } from 'react-router-dom';
import ServicesPreviewCard from '../components/Cards/ServicesPreviewCard';

import capaFotografar from '../media/capa-fotografar.jpg';
import capaMudar from '../media/capa-mudar.jpg';
import capaAvaliar from '../media/capa-avaliar.jpg';

import {
  evaluatePreviewCardDescription,
  evaluatePreviewCardTitle,
  movePreviewCardDescription,
  movePreviewCardTitle,
  photographerPreviewCardDescription,
  photographerPreviewCardTitle,
} from '../utils/text';
import RecentSearchesCard from '../components/Cards/RecentSearchesCard';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const HomePage = () => {
  const navigate = useNavigate();

  const recentSearches =
    JSON.parse(localStorage.getItem('recentSearches')) || [];

  return (
    <div className=' bg-emerald-50'>
      <section className='flex flex-col justify-center items-center p-8 bg-black  w-full'>
        <div className='flex flex-col mb-4'>
          <h1 className='text-emerald-400 font-extrabold  text-center text-xl md:text-3xl'>
            Encontre o que deseja
          </h1>
          <h1 className='text-white text-center text-sm md:text-lg'>
            Busque no melhor portal de imóveis do Brasil
          </h1>
        </div>
        <div className='flex gap-4'>
          <button
            onClick={() => navigate(`/buscar?action=rent`)}
            className='md:px-14 xs:px-10 px-5 py-3 text-black font-bold duration-150 bg-emerald-400 rounded-md hover:bg-emerald-500 active:shadow-lg'
          >
            Alugar
          </button>
          <button
            onClick={() => navigate('/buscar?action=sale')}
            className='md:px-12 xs:px-8  px-3 py-3 text-black font-bold duration-150 bg-emerald-400 rounded-md hover:bg-emerald-500 active:shadow-lg'
          >
            Comprar
          </button>
        </div>
      </section>
      <section className='mt-12 mx-auto w-fit'>
        {recentSearches.length > 0 && (
          <div className=' rounded-md mx-2 shadow-xl'>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='buscas-recentes'
              >
                <h1 className='text-gray-800 text-center text-xl sm:text-2xl font-semibold'>
                  Buscas recentes
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <div className='border-2 border-gray-300 shadow-inner grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 lg:flex-row p-6 gap-4 justify-center items-center'>
                  {recentSearches.map((item, idx) => (
                    <div
                      key={idx}
                      className='flex flex-col gap-2 justify-center p-1 border border-gray-300 pt-2 max-h-64 max-w-64 bg-slate-100 rounded-md hover:scale-105 hover:shadow-lg shadow-lg duration-200 cursor-pointer'
                    >
                      <h1 className='text-center font-semibold'>
                        {item.dateCreate}
                      </h1>
                      <RecentSearchesCard item={item} />
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </section>
      <section className='flex flex-col justify-center items-center my-20 gap-12'>
        <div className='w-3/4 '>
          <h1 className='text-black font-semibold  text-center text-xl md:text-3xl'>
            Serviços essenciais para seu imóvel
          </h1>
        </div>
        <div className='flex flex-col items-center justify-center gap-12 lg:flex-row mb-28'>
          <ServicesPreviewCard
            src={capaFotografar}
            title={photographerPreviewCardTitle}
            description={photographerPreviewCardDescription}
            badge='Fotos Profissionais'
            page='fotografar'
          />
          <ServicesPreviewCard
            src={capaMudar}
            title={movePreviewCardTitle}
            description={movePreviewCardDescription}
            badge='Mude-se com rapidez'
            page='mudar'
          />
          <ServicesPreviewCard
            src={capaAvaliar}
            title={evaluatePreviewCardTitle}
            description={evaluatePreviewCardDescription}
            badge='Profissionais qualificados'
            page='avaliar'
          />
        </div>
      </section>
    </div>
  );
};

export default HomePage;
