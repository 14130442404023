import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@uidotdev/usehooks';
import {
  MapContainer,
  FeatureGroup,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMap,
  LayersControl,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L, { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

import DrawnAreasBar from '../components/Layout/DrawnAreasBar';

import DrawnAreasPainel from '../components/Layout/DrawnAreasPainel';

import WrapperDrawingComponent from '../components/Drawing-mode/WrapperDrawingComponent';

import { drawingMapConfig, satellite, streets } from '../config/config';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const DrawingModePage = () => {
  const divRef = useRef(null);

  const isLargeDevice = useMediaQuery('only screen and (min-width : 1023px)');
  const isExtraSmallDevice = useMediaQuery(
    'only screen and (max-width : 350px)'
  );

  //uncomment next line to change the default tooltip text
  L.drawLocal.draw.handlers.polygon.tooltip.start =
    'Desenhe a área desejada no mapa';
  L.drawLocal.draw.handlers.polygon.tooltip.cont =
    'Continue desenhando até completar a área';
  L.drawLocal.draw.handlers.polygon.tooltip.end =
    'Clique no primeiro ponto para finalizar a área';

  L.drawLocal.edit.handlers.edit.tooltip.text = 'Arraste os pontos para editar';
  L.drawLocal.edit.handlers.edit.tooltip.subtext = '';
  // console.log('Draw:', L.drawLocal);

  return (
    <div className='flex flex-col lg:flex-row h-full'>
      <div className='lg:hidden block'>
        <DrawnAreasBar />
      </div>
      {/* <div className='flex h-full'> */}
      <DrawnAreasPainel />
      <MapContainer
        center={drawingMapConfig.map_default_center}
        zoom={drawingMapConfig.map_default_zoom}
        minZoom={drawingMapConfig.map_min_zoom}
        maxZoom={drawingMapConfig.map_max_zoom}
        // key={isLargeDevice ? 'large' : 'small'}
        zoomControl={true}
        trackResize={true}
        style={{ width: '100%', height: '100%' }}
      >
        {/* <FeatureGroup> */}
        <div ref={divRef}>
          <WrapperDrawingComponent />
        </div>
        <TileLayer
          attribution='Tiles &copy; <a href="https://www.esri.com/">Esri</a>'
          url={drawingMapConfig.map_tile_layer}
        />
        <LayersControl position='topright'>
          <LayersControl.BaseLayer name='Mapa' checked>
            <TileLayer url={streets} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name='Satélite'>
            <TileLayer url={satellite} />
          </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
    </div>
    // </div>
  );
};

export default DrawingModePage;
