import useSWR from 'swr';
import { serverUrl } from '../config/config';

// Create a post method to save the drawn area
export const saveDrawnArea = async (drawnArea) => {
  try {
    const response = await fetch(`${serverUrl}/polygons`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(drawnArea),
    });
    const text = await response.text();
    console.log('response: ', text);
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a get method to fetch the drawn areas
export const getDrawnAreas = async () => {
  const token = localStorage.getItem('token');
  if (!token) return;
  try {
    const response = await fetch(`${serverUrl}/polygons`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const deleteDrawnArea = async (id) => {
  try {
    const response = await fetch(`${serverUrl}/polygons/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const text = await response.text();
    console.log('response: ', text);
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const useDrawnAreas = () => {
  const { data, error, isLoading, isValidating } = useSWR(
    `drawnAreas`,
    getDrawnAreas
  );

  return {
    drawnAreasList: data,
    isLoading,
    isError: error,
    isValidating,
  };
};
