import { configureStore } from '@reduxjs/toolkit';

import loginValuesReducer from './loginValues';
import drawerValuesReducer from './drawerValues';
import addPropertyFormValuesReducer from './addPropertyFormValues';
import objectValuesReducer from './objectValues';
import mapValuesReducer from './mapValues';
import propertyValuesReducer from './propertyValues';
import markerValuesReducer from './markerValues';

const store = configureStore({
  reducer: {
    loginValues: loginValuesReducer,
    drawerValues: drawerValuesReducer,
    addPropertyFormValues: addPropertyFormValuesReducer,
    objectValues: objectValuesReducer,
    mapValues: mapValuesReducer,
    propertyValues: propertyValuesReducer,
    markerValues: markerValuesReducer,
  },
});

export default store;
