import { toast } from 'react-toastify';
import useSWR from 'swr';
import { serverUrl } from '../config/config';
const polyUtil = require('polyline-encoded');

// Create a post method to fetch the properties list within a given area

export const getPropertiesInGeometry = async (
  selectedSearchResult,
  filters,
  action
) => {
  // console.log('Filters: ', filters);

  if (!selectedSearchResult) {
    console.log('No selectedSearchResult');
    return;
  }
  // const encodedCoords = polyUtil.encode(
  //   selectedSearchResult.geometry.coordinates[0]
  // );
  // selectedSearchResult.geometry.coordinates = encodedCoords;
  // console.log('selectedSearchResult: ', encodedCoords);

  const formattedObject = {
    selectedSearchResult,
    filters,
    action,
  };
  try {
    const response = await fetch(`${serverUrl}/properties/searchGeometry`, {
      headers: {
        // Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formattedObject),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getPropertiesInMapBounds = async (
  zoom,
  mapBounds,
  selectedSearchResult,
  filters,
  action
) => {
  if (!zoom || !mapBounds) {
    return;
  }

  if (zoom < 15) {
    return;
  }

  if (selectedSearchResult) {
    return;
  }

  const formattedObject = {
    mapBounds,
    filters,
    action,
  };
  try {
    const response = await fetch(`${serverUrl}/properties/searchMapBounds`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formattedObject),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a post method to save the drawn area
export const addProperty = async (formData) => {
  try {
    const response = await fetch(`${serverUrl}/properties/new-property`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });
    if (!response.ok) {
      response.json().then((data) => {
        toast.error(data.message, {
          style: { zIndex: 1000 },
          position: 'bottom-center',
        });
      });

      return;
    }
    // console.log('response: ', response);
    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a get method to fetch the properties list for a given user
export const getPropertiesList = async () => {
  try {
    const response = await fetch(`${serverUrl}/properties`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a get method to fetch the property by id
export const getPropertyById = async (id) => {
  if (!id) {
    return;
  }
  try {
    const response = await fetch(`${serverUrl}/properties/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a get method to fetch the drawn polygon properties counter
export const getDrawnPolygonPropertiesCounter = async (
  selectedSearchResult
) => {
  if (!selectedSearchResult) {
    return;
  }

  try {
    const response = await fetch(
      `${serverUrl}/properties/drawnPolygonPropertiesCounter`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ selectedSearchResult: selectedSearchResult }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const propertyVisited = async (id) => {
  try {
    const response = await fetch(`${serverUrl}/properties/${id}/visit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const disableProperty = async (id) => {
  try {
    const response = await fetch(`${serverUrl}/properties/disable/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const deleteProperty = async (id) => {
  try {
    const response = await fetch(`${serverUrl}/properties/delete/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const useGetPropertiesByGeometry = (
  selectedSearchResult,
  filters,
  action,
  options
) => {
  const { data, error, isLoading, isValidating } = useSWR(
    `propertiesByGeometry`,
    () => getPropertiesInGeometry(selectedSearchResult, filters, action),
    options ?? null
  );

  return {
    propertiesByGeometry: data,
    isLoadingPropertiesByGeometry: isLoading,
    isErrorPropertiesByGeometry: error,
    isValidatingPropertiesByGeometry: isValidating,
  };
};

export const useGetPropertiesByMapBounds = (
  mapZoom,
  mapBounds,
  selectedSearchResult,
  filters,
  action,
  options
) => {
  const { data, error, isLoading, isValidating } = useSWR(
    `propertiesByMapBounds`,
    () =>
      getPropertiesInMapBounds(
        mapZoom,
        mapBounds,
        selectedSearchResult,
        filters,
        action
      ),
    options ?? null
  );

  return {
    propertiesByMapBounds: data,
    isLoadingPropertiesByMapBounds: isLoading,
    isErrorPropertiesByMapBounds: error,
    isValidatingPropertiesByMapBounds: isValidating,
  };
};

export const useGetPropertyById = (id) => {
  const { data, error, isLoading, isValidating } = useSWR(`property`, () =>
    getPropertyById(id)
  );

  return {
    property: data,
    isLoading,
    isError: error,
    isValidating,
  };
};

export const usePropertiesList = (id) => {
  const { data, error, isLoading, isValidating } = useSWR(
    `propertiesList`,
    getPropertiesList
  );

  return {
    propertiesList: data,
    isLoading,
    isError: error,
    isValidating,
  };
};

export const useDrawnPolygonPropertiesCounter = (selectedSearchResult) => {
  const { data, error, isLoading, isValidating } = useSWR(
    `drawnPolygonPropertiesCounter`,
    () => getDrawnPolygonPropertiesCounter(selectedSearchResult)
  );

  return {
    drawnPolygonPropertiesCounter: data,
    isLoading,
    isError: error,
    isValidating,
  };
};
