import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

const DeleteAlertDialog = ({
  isDeleteAlertDialogOpen,
  setIsDeleteAlertDialogOpen,
  handleDeleteAlert,
}) => {
  return (
    <Dialog
      open={isDeleteAlertDialogOpen}
      onClose={() => setIsDeleteAlertDialogOpen(false)}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>Excluir alerta</DialogTitle>
      <DialogContent dividers={true}>
        <div className='flex flex-col gap-3'>
          <p>Tem certeza que deseja excluir este alerta?</p>
          <p>Esta ação não poderá ser desfeita.</p>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
          onClick={() => setIsDeleteAlertDialogOpen(false)}
        >
          Cancelar
        </button>
        <button
          className='bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded'
          onClick={handleDeleteAlert}
        >
          Criar
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAlertDialog;
