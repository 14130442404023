import React, { useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { drawingMapConfig } from '../../config/config';
import { useMediaQuery } from '@uidotdev/usehooks';
import WrapperRecentSearchesComponent from '../RecentSearches/WrapperRecentSearchesComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { objectValuesActions } from '../../store/objectValues';

const RecentSearchesCard = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLargeDevice = useMediaQuery('only screen and (min-width : 1023px)');
  const isExtraSmallDevice = useMediaQuery(
    'only screen and (max-width : 350px)'
  );

  const handleSelectedRecentSearch = () => {
    const searchParams = new URLSearchParams();

    Object.keys(item.filters).forEach((key) => {
      if (item.filters[key] !== 'any' && item.filters[key] !== '') {
        searchParams.append(key, item.filters[key]);
      }
    });
    console.log('Selected:', item);
    console.log(`/buscar?${searchParams.toString()}`);

    dispatch(
      objectValuesActions.setSelectedSearchResult(item.selectedSearchResult)
    );

    navigate(`/buscar?${searchParams.toString()}`);
  };

  return (
    <div
      className='flex w-full h-full cursor-pointer'
      onClick={handleSelectedRecentSearch}
    >
      <MapContainer
        center={drawingMapConfig.map_default_center}
        zoom={drawingMapConfig.map_default_zoom}
        minZoom={drawingMapConfig.map_min_zoom}
        maxZoom={drawingMapConfig.map_max_zoom}
        key={isLargeDevice ? 'large' : 'small'}
        dragging={false}
        touchZoom={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        boxZoom={false}
        keyboard={false}
        tapHold={false}
        tap={false}
        zoomControl={false}
        style={{
          width: '350px',
          height: '210px',
        }}
      >
        <WrapperRecentSearchesComponent item={item} />
        <TileLayer
          attribution='Tiles &copy; <a href="https://www.esri.com/">Esri</a>'
          url={drawingMapConfig.map_tile_layer}
        />
      </MapContainer>
    </div>
  );
};

export default RecentSearchesCard;
