import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { drawingMapConfig } from '../../config/config';
import { useMediaQuery } from '@uidotdev/usehooks';
import WrapperRecentSearchesComponent from '../RecentSearches/WrapperRecentSearchesComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { objectValuesActions } from '../../store/objectValues';
import WrapperMyAlertsComponent from '../MyAlerts/WrapperMyAlertsComponent';
import { Backdrop, CircularProgress, IconButton, Tooltip } from '@mui/material';
import {
  CircleNotifications,
  CircleNotificationsRounded,
  Delete,
  Edit,
  Feed,
  NotificationsActive,
  NotificationsOff,
  Search,
} from '@mui/icons-material';
import { deleteAlert, updateAlert } from '../../services/alertService';
import { mutate } from 'swr';
import { toast } from 'react-toastify';
import DeleteAlertDialog from '../Dialogs/DeleteAlertDialog';

const MyAlertsCard = ({ item }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleteAlertDialogOpen, setIsDeleteAlertDialogOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLargeDevice = useMediaQuery('only screen and (min-width : 1023px)');
  const isExtraSmallDevice = useMediaQuery(
    'only screen and (max-width : 350px)'
  );

  const handleSelectedAlert = () => {
    const searchParams = new URLSearchParams();

    item.filters.map((filter) => {
      if (filter.value !== 'any' && filter.value !== '') {
        searchParams.append(filter.key, filter.value);
      }
    });

    console.log('Selected:', item);
    console.log(`/buscar?${searchParams.toString()}`);

    dispatch(
      objectValuesActions.setSelectedSearchResult({
        type: 'Feature',
        properties: {},
        geometry: item.geometry,
      })
    );

    navigate(`/buscar?${searchParams.toString()}`);
  };

  const handleDisableAlert = () => {
    try {
      setIsUpdating(true);
      updateAlert(item)
        .then((response) => {
          console.log(response);
          toast.success(response.message, {
            position: 'bottom-center',
          });
          mutate('alerts');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    } catch (error) {
      setIsUpdating(false);
      console.log(error);
    }
  };

  const handleDeleteAlert = () => {
    console.log('Excluir alerta: ', item);
    try {
      deleteAlert(item)
        .then((response) => {
          console.log(response);
          toast.success(response.message, {
            position: 'bottom-center',
          });
          mutate('alerts');
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setIsDeleteAlertDialogOpen(false);
  };

  if (isUpdating) {
    return (
      <Backdrop open={isUpdating} style={{ zIndex: 999 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <div
      className='flex flex-col w-full h-full  border-2 border-gray-200 rounded-lg p-4 gap-4 shadow-lg bg-white'
      // onClick={handleSelectedAlert}
    >
      <DeleteAlertDialog
        handleDeleteAlert={handleDeleteAlert}
        isDeleteAlertDialogOpen={isDeleteAlertDialogOpen}
        setIsDeleteAlertDialogOpen={setIsDeleteAlertDialogOpen}
      />
      <div className='border-2 border-gray-200 rounded-lg p-1'>
        <MapContainer
          center={drawingMapConfig.map_default_center}
          zoom={drawingMapConfig.map_default_zoom}
          minZoom={drawingMapConfig.map_min_zoom}
          maxZoom={drawingMapConfig.map_max_zoom}
          key={isLargeDevice ? 'large' : 'small'}
          dragging={false}
          touchZoom={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          boxZoom={false}
          keyboard={false}
          tapHold={false}
          tap={false}
          zoomControl={false}
          style={{
            width: '210px',
            height: '210px',
          }}
        >
          <WrapperMyAlertsComponent item={item} />
          <TileLayer
            attribution='Tiles &copy; <a href="https://www.esri.com/">Esri</a>'
            url={drawingMapConfig.map_tile_layer}
          />
        </MapContainer>
      </div>
      <div className='flex flex-col gap-2'>
        <div className='flex gap-2'>
          <h2 className='text-sm text-gray-800'>Criado em:</h2>
          <h2 className='text-sm font-bold text-gray-800'>
            {new Intl.DateTimeFormat('pt-BR').format(new Date(item.dateCreate))}
          </h2>
        </div>
        <div className='flex gap-2 items-center'>
          <h2 className='text-sm text-gray-800'>Status:</h2>
          <div className=''>
            <Tooltip
              title={`${item.status === 'active' ? 'Ativo' : 'Desativado'}`}
              placement='right'
              arrow
            >
              <CircleNotificationsRounded
                color={`${item.status === 'active' ? 'success' : 'error'}`}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='flex justify-evenly'>
        <Tooltip title='Buscar no mapa' arrow>
          <IconButton onClick={handleSelectedAlert}>
            <Search />
          </IconButton>
        </Tooltip>
        <Tooltip title='Detalhes' arrow>
          <IconButton>
            <Feed />
          </IconButton>
        </Tooltip>
        <Tooltip title='Editar filtros' arrow>
          <IconButton>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`${
            item.status === 'active' ? 'Desativar alerta' : 'Ativar alerta'
          }`}
          arrow
        >
          {item.status === 'active' ? (
            <IconButton onClick={handleDisableAlert}>
              <NotificationsOff />
            </IconButton>
          ) : (
            <IconButton onClick={handleDisableAlert}>
              <NotificationsActive />
            </IconButton>
          )}
        </Tooltip>
        <Tooltip title='Excluir alerta' arrow>
          <IconButton onClick={() => setIsDeleteAlertDialogOpen(true)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default MyAlertsCard;
