import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  mapZoom: null,
  mapBounds: null,
};

const mapValuesSlice = createSlice({
  name: 'mapValues',
  initialState: initialFormState,
  reducers: {
    setMapZoom(state, action) {
      state.mapZoom = action.payload;
    },
    setMapBounds(state, action) {
      state.mapBounds = action.payload;
    },
  },
});

export const mapValuesActions = mapValuesSlice.actions;

export default mapValuesSlice.reducer;
