import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const WrapperPropertyComponent = ({ item }) => {
  const map = useMap();

  useEffect(() => {
    map.whenReady(() => {
      if (!item) {
        return;
      }

      const propertyMarker = L.marker([
        item.location.coordinates[1],
        item.location.coordinates[0],
      ]).addTo(map);

      propertyMarker.on('click', () => {
        map.flyTo(
          [item.location.coordinates[1], item.location.coordinates[0]],
          15,
          {
            animate: true,
            duration: 1,
          }
        );
      });
    });
  }, [map]);

  return null;
};

export default WrapperPropertyComponent;
