import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import * as turf from '@turf/turf';

const WrapperMyAlertsComponent = ({ item }) => {
  const map = useMap();

  useEffect(() => {
    map.whenReady(() => {
      if (!item) {
        return;
      }

      if (
        item.geometry.type === 'Polygon' ||
        item.geometry.type === 'MultiPolygon'
      ) {
        const geoJson = L.geoJson(item.geometry);
        geoJson.addTo(map);
        map.flyToBounds(geoJson.getBounds(), {
          animate: false,
        });
      } else if (item.geometry.type === 'Point') {
        const searchRadius = item.filters.find(
          (filter) => filter.key === 'searchRadius'
        );
        const geoJson = L.geoJson(item.geometry, {
          pointToLayer: (feature, latlng) => {
            return L.circle(latlng, {
              radius: Number(searchRadius.value) * 1000 || 100,
            });
          },
        });

        geoJson.addTo(map);
        map.flyToBounds(geoJson.getBounds(), {
          animate: false,
        });
      } else if (item.geometry.type === 'LineString') {
        const center = turf.center(item.geometry);
        const searchRadius = item.filters.find(
          (filter) => filter.key === 'searchRadius'
        );

        const geoJsonLine = L.geoJSON(item.geometry, {
          style: function (feature) {
            //color red
            return { color: 'black' };
          },
        });
        const geoJsonCircle = L.geoJSON(center, {
          pointToLayer: function (feature, latlng) {
            return L.circle(latlng, {
              radius: (searchRadius || 0.1) * 1000,
            });
          },
        });

        geoJsonLine.addTo(map);
        geoJsonCircle.addTo(map);

        map.flyToBounds(geoJsonCircle.getBounds(), {
          animate: false,
        });
      }
    });
  }, [map]);

  return null;
};

export default WrapperMyAlertsComponent;
