import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';

import { auth } from '../../firebase-config';
import { useForm } from 'react-hook-form';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { toast } from 'react-toastify';

const RegisterForm = ({ setLogin }) => {
  const handleRegister = ({ email, password, name, lastname }) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log('User is signed in', user);
        updateProfile(user, {
          displayName: `${name} ${lastname}`,
          photoURL: 'https://example.com/jane-q-user/profile.jpg',
        }).then(() => {
          // Profile updated!
          // ...
          toast.success('Conta criada com sucesso!', {
            autoClose: 1500,
            position: 'bottom-center',
          });
        });
      })
      // ...

      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        switch (errorCode) {
          case 'auth/invalid-email':
            toast.error('Email inválido', {
              autoClose: 1500,
              position: 'bottom-center',
            });
            break;
          case 'auth/email-already-in-use':
            toast.error('Email já cadastrado', {
              autoClose: 1500,
              position: 'bottom-center',
            });
            break;
          case 'auth/weak-password':
            toast.error('A senha deve conter pelo menos 6 caracteres', {
              autoClose: 1500,
              position: 'bottom-center',
            });
            break;
          default:
            toast.error('Erro ao criar conta', {
              autoClose: 1500,
              position: 'bottom-center',
            });
            break;
        }
        console.log('Error: ', errorCode);
      });
  };

  const {
    formState,
    register,

    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    resetField,
  } = useForm({});

  return (
    <div>
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className='flex flex-col p-4 gap-8 mt-6'>
          <div className='flex flex-col gap-4 flex-wrap'>
            <Typography variant='h6' fontSize={16} fontWeight='bold'>
              Crie sua conta
            </Typography>
            <TextField
              {...register('email', { required: true })}
              id='email-button'
              label='Email'
              placeholder='Digite seu email'
              type='email'
            />
            <TextField
              {...register('password', { required: true })}
              id='password-button'
              label='Senha'
              placeholder='Digite sua senha'
              type='password'
            />
            <TextField
              {...register('name', { required: true })}
              id='name-button'
              label='Nome'
              placeholder='Digite seu nome'
              type='text'
            />
            <TextField
              {...register('lastname', { required: true })}
              id='lastname-button'
              label='Sobrenome'
              placeholder='Digite seu sobrenome'
              type='text'
            />
            {/* <TextField
              {...register('phone', { required: true })}
              id='phone-button'
              label='Telefone'
              placeholder='Digite seu telefone'
              type='number'
            /> */}
            <p
              className='hover:text-emerald-800 hover:underline hover:cursor-pointer w-fit'
              onClick={() => setLogin(true)}
            >
              Já possui conta? Faça login
            </p>
          </div>
          <Button
            className=''
            variant='contained'
            color='success'
            size='large'
            type='submit'
          >
            Criar Conta
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
