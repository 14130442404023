import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import {
  ArrowBack,
  MenuBook,
  MenuOutlined,
  PersonOutlineOutlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';
import DrawnAreasDrawer from '../Drawers/DrawnAreasDrawer';
import polygonIcon from '../../media/icons/polygonIcon.svg';

const DrawnAreasBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <nav className='flex items-center justify-center border-b p-2 bg-emerald-50 lg:justify-around'>
      <DrawnAreasDrawer />
      {/* <div className='lg:hidden flex items-center'>
        <button
          onClick={() => navigate('/')}
          className='flex items-center gap-2 px-3 py-1.5 border-transparent text-sm text-black duration-100  rounded-lg hover:bg-emerald-400'
        >
          <ArrowBack /> Sair
        </button>
      </div> */}

      <div>
        <div className='lg:hidden flex items-center'>
          <button
            onClick={() =>
              dispatch(drawerValuesActions.setDrawnAreasDrawer(true))
            }
            className='flex items-center gap-2 px-3 py-1.5 border-transparent text-sm text-black duration-100  rounded-lg hover:bg-emerald-400 '
          >
            <img src={polygonIcon} className='w-5 h-5' /> Áreas Desenhadas
          </button>
        </div>
      </div>
    </nav>
  );
};

export default DrawnAreasBar;
