import React from 'react';
import DrawingButtonsDiv from './DrawingButtonsDiv';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

const WrapperDrawingComponent = () => {
  const map = useMap();

  const polygonHandler = new L.Draw.Polygon(map, {
    shapeOptions: {
      color: '#10B981',
      weight: 3,
      opacity: 1,
      fill: false,
      fillColor: '#10B981',
      fillOpacity: 0.5,
      clickable: true,
    },
    repeatMode: false,
    allowIntersection: false,
    showArea: true,
    metric: ['km', 'm'],
    precision: 2,
    drawError: {
      color: '#FF0000',
      message: '<strong>As linhas não podem se cruzar!</strong>',
      timeout: 1000,
    },
    icon: new L.DivIcon({
      iconSize: new L.Point(8, 8),
      className: 'leaflet-div-icon leaflet-editing-icon',
    }),
    edit: {
      poly: {
        allowIntersection: false,
      },
      featureGroup: new L.FeatureGroup(),
    },
  });

  return (
    <div>
      <DrawingButtonsDiv map={map} polygonHandler={polygonHandler} />
    </div>
  );
};

export default WrapperDrawingComponent;
