import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDrawnArea,
  saveDrawnArea,
  useDrawnAreas,
} from '../../services/drawnAreasService';
import { mutate } from 'swr';
import {
  Backdrop,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Tooltip,
} from '@mui/material';
import { ArrowBack, Delete } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { objectValuesActions } from '../../store/objectValues';
import { drawerValuesActions } from '../../store/drawerValues';
import * as turf from '@turf/turf';

const DrawnAreasPainel = () => {
  const { selectedSearchResult, drawnAreaId } = useSelector(
    (state) => state.objectValues
  );

  const [areaName, setAreaName] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector((state) => state.loginValues);

  const { drawnAreasList, isLoading, isError } = useDrawnAreas({
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: true,
  });

  const handleDeleteDrawnArea = async (id) => {
    try {
      await deleteDrawnArea(id);
      mutate('drawnAreas');
      setIsDeleteDialogOpen(false);

      toast.success('Área deletada com sucesso!', {
        position: 'bottom-center',
      });
    } catch (error) {
      console.log(error);
      toast.error('Erro ao deletar a área', {
        position: 'bottom-center',
      });
    }
  };

  const handleSaveDrawnArea = async () => {
    if (!areaName) return;

    const checkAreaEquality = () => {
      if (
        drawnAreasList &&
        drawnAreasList.length > 0 &&
        drawnAreasList.find(
          (area) =>
            JSON.stringify(area.location.coordinates[0]) ===
            JSON.stringify(selectedSearchResult.geometry.coordinates[0])
        )
      ) {
        return true;
      }
      return false;
    };

    if (checkAreaEquality()) {
      toast.error('Área já salva!', {
        position: 'bottom-center',
      });
      return;
    }
    const formattedObject = {
      name: areaName,

      // invert the coordinates to match the format of the server (lng, lat)
      location: {
        type: 'Polygon',
        coordinates: selectedSearchResult.geometry.coordinates,
      },
    };

    try {
      await saveDrawnArea(formattedObject);
      mutate('drawnAreas');

      toast.success('Área salva com sucesso!', {
        position: 'bottom-center',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedArea = (area) => {
    const turfPolygon = turf.polygon(area.location.coordinates);
    dispatch(objectValuesActions.setSelectedSearchResult(turfPolygon));
    dispatch(objectValuesActions.setDrawnAreaId(area._id));
  };

  useEffect(() => {
    if (userId && localStorage.getItem('token')) {
      console.log('drawnAreasList:', drawnAreasList);

      mutate('drawnAreas');
    }
  }, [userId]);

  if (isLoading) {
    return (
      <div className='hidden w-96 lg:flex lg:flex-col justify-center items-center h-full gap-2'>
        {/* <Backdrop open={true}> */}
        <CircularProgress color='inherit' />
        <h1 className='text-xl font-bold '>Carregando áreas salvas...</h1>
        {/* </Backdrop> */}
      </div>
    );
  }

  return (
    <div className='hidden w-96 lg:flex lg:flex-col justify-center items-center h-full border-r-2 border-gray-400'>
      {!userId ? (
        <div className='flex flex-col w-full items-center justify-center gap-4'>
          <h1 className='font-bold  text-center text-lg my-6 px-4'>
            Faça login para salvar áreas desenhadas
          </h1>
          <button
            className='bg-emerald-400 font-semibold px-4 py-2 rounded-md hover:bg-emerald-500'
            onClick={() => {
              dispatch(drawerValuesActions.setLoginDrawer(true));
            }}
          >
            Login
          </button>
        </div>
      ) : (
        <div className='flex flex-col w-full items-center gap-4'>
          <Dialog
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
          >
            <DialogTitle>Excluir Área</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Você tem certeza que deseja excluir essa área?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => setIsDeleteDialogOpen(false)}
                className='bg-gray-400 text-white font-bold px-4 py-2 rounded-md hover:bg-gray-500'
              >
                Cancelar
              </button>
              <button
                onClick={() => {
                  handleDeleteDrawnArea(idToDelete);
                }}
                className='bg-red-400 text-white font-bold px-4 py-2 rounded-md hover:bg-red-500'
              >
                Excluir
              </button>
            </DialogActions>
          </Dialog>
          <h1 className='text-emerald-400 font-extrabold  text-center text-xl my-6'>
            Desenhar Áreas
          </h1>
          <input
            type='text'
            onChange={(e) => {
              setAreaName(e.target.value);
            }}
            placeholder='Nome da área'
            className='placeholder:font-light hidden w-4/5 md:flex h-12 px-4 text-black font-bold duration-150 bg-white border rounded-md hover:bg-gray-100 active:shadow-lg'
          />
          <button
            onClick={handleSaveDrawnArea}
            disabled={!selectedSearchResult}
            className='my-4 w-4/5 py-3 text-black font-medium duration-500 bg-emerald-400 rounded-md hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-200 '
          >
            Salvar Área
          </button>
          <h1 className='text-emerald-400 font-extrabold  text-center text-xl my-6'>
            Minhas Áreas
          </h1>
          {!isLoading &&
          !isError &&
          drawnAreasList &&
          !drawnAreasList.message ? (
            drawnAreasList.map((area) => {
              return (
                <div
                  key={area._id}
                  className={`flex justify-between w-4/5 border rounded-md p-2 shadow-md hover:bg-emerald-500 hover:border-black duration-500 cursor-pointer ${
                    drawnAreaId === area._id ? 'bg-emerald-400' : 'bg-white'
                  } ${drawnAreaId === area._id ? 'border-black' : 'border'}`}
                  onClick={() => handleSelectedArea(area)}
                >
                  <p>{area.name}</p>
                  <div className='hover:scale-150 duration-200'>
                    <Tooltip
                      title='Excluir'
                      enterDelay={300}
                      leaveDelay={100}
                      placement='left'
                      arrow
                    >
                      <IconButton
                        aria-label='delete'
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDeleteDialogOpen(true);
                          setIdToDelete(area._id);
                        }}
                        sx={{
                          color: 'black',
                          transition: 'color 0.3s', // Add transition duration
                          '&:hover': {
                            color: '#d32a2a', // Change this to your desired hover color
                          },
                        }}
                      >
                        <Delete fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='flex gap-2 items-center'>
              <CircularProgress color='inherit' />
              <h1 className='font-bold text-xl text-center text-emerald-500'>
                Buscando áreas salvas...
              </h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DrawnAreasPainel;
