import { toast } from 'react-toastify';
import { serverUrl } from '../config/config';

export const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    console.log('Notification permission granted.');
  } else {
    console.log('Notification permission denied.');
  }
  return permission;
};

export const subscribeToPushService = async () => {
  if (!('serviceWorker' in navigator)) {
    toast.error(
      'Tente novamente em um navegador compatível com Service Workers.',
      { style: { zIndex: 1000 } }
    );
    return;
  }

  try {
    const registration = await navigator.serviceWorker.ready;
    console.log('Service Worker ready: ', registration);

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_vapidKey, // Replace with your VAPID public key
    });

    // Send the subscription to your server
    const savedSubscription = await fetch(`${serverUrl}/subscriptions`, {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`, // Replace with your API key
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            console.log('Error123:', error.message);
            throw new Error(error.message);
          });
        }
      })
      .then((response) => {
        // toast.success('Subscription sent to server');
        console.log('Subscription sent to server:', response);
        return subscription;
      })
      .catch((error) => {
        // toast.error(error.message);
        console.log(error.message);
      });

    console.log('Subscribed to push service:', savedSubscription);
    return savedSubscription;
  } catch (error) {
    console.error(
      'Error during service worker registration or subscription:',
      error
    );
  }
};
