import {
  AdminPanelSettings,
  ArrowBackOutlined,
  Bathtub,
  BedOutlined,
  Camera,
  CameraAltOutlined,
  Chair,
  CreditScore,
  DirectionsCarFilledOutlined,
  Elevator,
  Favorite,
  FavoriteBorderOutlined,
  List,
  Pets,
  Share,
  ShareOutlined,
  ShowerOutlined,
  SpaceDashboardOutlined,
  Stairs,
  WhatsApp,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import { useSelector } from 'react-redux';
import housePlaceholder from '../../media/housePlaceholder.webp';
import {
  hasVisitedProperty,
  markPropertyAsVisited,
} from '../../utils/visitedProperties';
import { propertyVisited } from '../../services/propertyService';
import { toast } from 'react-toastify';
import WrapperPropertyComponent from '../Property/WrapperPropertyComponent';
import { MapContainer, TileLayer } from 'react-leaflet';
import { mapConfig } from '../../config/config';
import conciergeImg from '../../media/icons/concierge.png';
import { Cross1Icon, Cross2Icon } from '@radix-ui/react-icons';
import { Helmet } from 'react-helmet';
import logo from '../../media/icons/rentia-logo-192.svg';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

const PropertyDetailsDialog = ({
  propertyDetailsDrawer,
  handleClosePropertyDetailsDrawer,
  isExtraSmallDevice,
}) => {
  const { selectedProperty } = useSelector((state) => state.propertyValues);

  const handleWhatsapp = () => {
    const url = `https://wa.me/55${
      selectedProperty.ownerPhone || '32991071618'
    }?text=Olá! Estou interessado neste imóvel: https://rentia.app.br/imovel/${
      selectedProperty._id
    }`;
    window.open(url, '_blank');
  };

  const copyToClipboard = () => {
    const url = `https://rentia.app.br/imovel/${selectedProperty._id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success('Link do imóvel copiado', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .catch((error) => {
        console.error('Error copying URL to clipboard:', error);
      });
  };

  useEffect(() => {
    if (!propertyDetailsDrawer) {
      return;
    }

    if (!selectedProperty) {
      return;
    }

    if (!hasVisitedProperty(selectedProperty._id)) {
      propertyVisited(selectedProperty._id)
        .then((data) => {
          // console.log('Data:', data);
          if (data) {
            markPropertyAsVisited(selectedProperty._id);
            console.log('Property visited:', selectedProperty._id);
          }
        })
        .catch((error) => {
          console.error('Error incrementing visit counter:', error);
        });
    }
  }, [selectedProperty, propertyDetailsDrawer]);

  return (
    <div style={{ backgroundColor: '#ecfdf5' }}>
      {selectedProperty && (
        <Dialog
          fullScreen
          open={propertyDetailsDrawer}
          onClose={handleClosePropertyDetailsDrawer}
        >
          <div className='flex flex-col gap-1'>
            <SpeedDial
              ariaLabel='Menu de ações'
              sx={{ position: 'fixed', bottom: 30, right: 30 }}
              icon={<SpeedDialIcon />}
            >
              <SpeedDialAction
                icon={<Share />}
                tooltipTitle={'Compartilhar'}
                onClick={copyToClipboard}
              />
              <SpeedDialAction
                icon={<Favorite />}
                tooltipTitle={'Adicionar aos favoritos'}
              />
              <SpeedDialAction
                icon={<WhatsApp />}
                tooltipTitle={'Whatsapp'}
                onClick={handleWhatsapp}
              />
            </SpeedDial>
            <div className='flex justify-between items-center p-4'>
              <h1 className='text-xl font-bold'>Detalhes do Imóvel</h1>
              <div className='flex self-end'>
                <IconButton onClick={handleClosePropertyDetailsDrawer}>
                  <Cross1Icon />
                </IconButton>
              </div>
            </div>
            <div className='flex flex-col  pl-4'>
              <div className='flex gap-2'>
                <h1 className='text-sm md:text-base font-bold'>Id:</h1>
                <h1 className='text-sm md:text-base '>
                  {selectedProperty._id}
                </h1>
              </div>
              <div className='flex gap-2'>
                <h1 className='text-sm md:text-base font-bold'>
                  Adicionado em:
                </h1>
                <h1 className='text-sm md:text-base'>
                  {new Date(selectedProperty.dateCreate).toLocaleDateString()}
                </h1>
              </div>
            </div>
            <div className='relative  rounded-sm mx-2 shadow-lg'>
              <Carousel
                responsive={responsive}
                infinite={true}
                slidesToSlide={1}
                className='w-full'
                // containerClass='container'
                swipeable
                draggable
                autoPlay={true}
                autoPlaySpeed={10000}
                partialVisible={true}
                // centerMode={true}
                keyBoardControl={true}
                arrows={true}
              >
                {selectedProperty &&
                  [...selectedProperty.images]
                    .sort((a, b) => {
                      if (a.isCover) return -1;
                      if (b.isCover) return 1;
                    })
                    .map((image) => (
                      <div className='flex mx-2' key={image.name}>
                        <img
                          src={image.url || housePlaceholder}
                          alt='selectedProperty'
                          className='w-fit h-80 object-cover'
                        />
                      </div>
                    ))}
              </Carousel>
              <div className='absolute top-4 left-10'>
                <Button
                  startIcon={<CameraAltOutlined />}
                  variant='contained'
                  color='success'
                >
                  {`${selectedProperty.images.length} ${
                    selectedProperty.images.length > 1 ? 'fotos' : 'foto'
                  }`}
                </Button>
              </div>
            </div>
            <Divider />
            <div className='flex flex-col w-full p-4'>
              <h1 className='text-base md:text-lg lg:text-xl font-bold'>
                Valor total
              </h1>
              <h1 className='text-lg md:text-xl lg:text-2xl font-bold text-emerald-700'>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(selectedProperty.totalPrice)}
              </h1>
              <div className='flex flex-col mt-2'>
                <div className='flex gap-2'>
                  <h1 className='text-sm'>Aluguel:</h1>
                  <h1 className='text-sm'>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(selectedProperty.price)}
                  </h1>
                </div>
                <div className='flex gap-2'>
                  <h1 className='text-sm'>Condomínio:</h1>
                  <h1 className='text-sm'>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(selectedProperty.monthlyTax)}
                  </h1>
                </div>
                <div className='flex gap-2'>
                  <h1 className='text-sm'>IPTU:</h1>
                  <h1 className='text-sm'>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(selectedProperty.iptu / 12)}
                  </h1>
                </div>
              </div>
            </div>
            <div className='flex flex-col p-4'>
              <h1 className='text-base md:text-lg font-bold'>Tipo de imóvel</h1>
              <h1 className='text-base md:text-lg'>{selectedProperty.type}</h1>
            </div>
            <div className='flex flex-col gap-6 p-4'>
              <h1 className='text-base md:text-lg font-bold'>
                Características do imóvel
              </h1>

              <div className='grid md:w-fit grid-cols-2 xs:grid-cols-3 md:grid-cols-4 gap-8 border border-gray-200 rounded-lg p-4 shadow-lg bg-slate-200'>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Área</h1>
                  <div className='flex items-center gap-1'>
                    <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.area}m²`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Quartos</h1>
                  <div className='flex items-center gap-1'>
                    <BedOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.bedrooms}`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Banheiros</h1>
                  <div className='flex items-center gap-1'>
                    <ShowerOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.bathrooms}`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Suites</h1>
                  <div className='flex items-center gap-1'>
                    <Bathtub className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.suites}`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Vagas</h1>
                  <div className='flex items-center gap-1'>
                    <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.parkingSlots}`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Mobiliado</h1>
                  <div className='flex items-center gap-1'>
                    <Chair className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.furnished ? 'Sim' : 'Não'
                    }`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Fiador</h1>
                  <div className='flex items-center gap-1'>
                    <AdminPanelSettings className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.guarantor ? 'Sim' : 'Não'
                    }`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Caução</h1>
                  <div className='flex items-center gap-1'>
                    <CreditScore className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.deposit ? 'Sim' : 'Não'
                    }`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Portaria</h1>
                  <div className='flex items-center gap-1'>
                    <img src={conciergeImg} className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.deposit ? 'Sim' : 'Não'
                    }`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Elevador</h1>
                  <div className='flex items-center gap-1'>
                    <Elevator className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.elevator ? 'Sim' : 'Não'
                    }`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Andar</h1>
                  <div className='flex items-center gap-1'>
                    <Stairs className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.floorNumber}`}</p>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <h1 className='font-medium'>Aceita pet?</h1>
                  <div className='flex items-center gap-1'>
                    <Pets className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.petFriendly ? 'Sim' : 'Não'
                    }`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col w-full gap-4 p-4'>
              <div className='flex flex-col w-full gap-2'>
                <h1 className='text-lg font-bold'>Descrição completa</h1>
                <p>{selectedProperty.description}</p>
              </div>
            </div>
            <div className='flex flex-col w-full gap-4 p-4'>
              <div className='flex flex-col w-full gap-2'>
                <h1 className='text-lg font-bold'>Localização</h1>
                <p>{`${selectedProperty.address}, ${selectedProperty.neighborhood}, ${selectedProperty.city}`}</p>
              </div>
              <div className='flex flex-col w-full gap-2 border border-gray-200 rounded-lg p-2 shadow-lg bg-slate-200'>
                {selectedProperty && (
                  <MapContainer
                    // center={[
                    //   selectedProperty.location.coordinates[0],
                    //   selectedProperty.location.coordinates[1],
                    // ]}
                    center={[
                      selectedProperty.location.coordinates[1],
                      selectedProperty.location.coordinates[0],
                    ]}
                    zoom={16}
                    style={{
                      // width: '350px',
                      height: isExtraSmallDevice ? '300px' : '410px',
                    }}
                  >
                    {/* <WrapperRecentSearchesComponent item={item} /> */}
                    <WrapperPropertyComponent item={selectedProperty} />
                    <TileLayer
                      attribution='Tiles &copy; <a href="https://www.esri.com/">Esri</a>'
                      url={mapConfig.map_tile_layer}
                    />
                  </MapContainer>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default PropertyDetailsDialog;
