import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import * as turf from '@turf/turf';

const WrapperRecentSearchesComponent = ({ item }) => {
  const map = useMap();

  useEffect(() => {
    map.whenReady(() => {
      if (!item) {
        return;
      }

      if (
        item.selectedSearchResult.geometry.type === 'Polygon' ||
        item.selectedSearchResult.geometry.type === 'MultiPolygon'
      ) {
        const geoJson = L.geoJson(item.selectedSearchResult, {
          style: {
            color: '#10B981',
          },
        });
        geoJson.addTo(map);
        map.flyToBounds(geoJson.getBounds(), {
          animate: false,
        });
      } else if (item.selectedSearchResult.geometry.type === 'Linestring') {
        const center = turf.center(item.selectedSearchResult);
        console.log('Center:', center);

        const geoJsonLine = L.geoJSON(item.selectedSearchResult, {
          style: function (feature) {
            //color red
            return { color: 'black' };
          },
        });
        const geoJsonCircle = L.geoJSON(center, {
          pointToLayer: function (feature, latlng) {
            return L.circle(latlng, {
              radius: (item.filters.searchRadius || 0.1) * 1000,
            });
          },
        });

        geoJsonLine.addTo(map);
        geoJsonCircle.addTo(map);

        map.flyToBounds(geoJsonCircle.getBounds(), {
          animate: false,
        });
      } else if (item.selectedSearchResult.geometry.type === 'Point') {
        const geoJson = L.geoJSON(item.selectedSearchResult, {
          pointToLayer: function (feature, latlng) {
            return L.circle(latlng, {
              radius: (item.filters.searchRadius || 0.1) * 1000,
            });
          },
        });
        geoJson.addTo(map);
        map.flyToBounds(geoJson.getBounds(), {
          animate: false,
        });
      }
    });
  }, [map]);

  return null;
};

export default WrapperRecentSearchesComponent;
