import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteProperty,
  disableProperty,
  usePropertiesList,
} from '../services/propertyService';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  AddHomeWork,
  BedOutlined,
  Delete,
  DirectionsCarFilledOutlined,
  Domain,
  DomainAdd,
  DomainDisabled,
  Edit,
  MapsHomeWork,
  Search,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import { useMediaQuery } from '@uidotdev/usehooks';
import housePlaceholder from '../media/housePlaceholder.webp';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 1,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const MyPropertiesPage = () => {
  const [filterText, setFilterText] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const isLargeDevice = useMediaQuery('only screen and (max-width : 1200px)');

  const navigate = useNavigate();
  const { propertiesList = [], isLoading, isError } = usePropertiesList();

  const handlePropertiesFilter = (e) => {
    setFilterText(e.target.value);
    console.log(filterText);
  };

  const handleDisableProperty = (id, status) => {
    console.log('Desativar propriedade: ', id);
    disableProperty(id)
      .then(() => {
        console.log('Propriedade desativada com sucesso');
        toast.success(
          status === 'active'
            ? 'Imóvel desativado com sucesso'
            : 'Imóvel ativado com sucesso',
          {
            autoClose: 1500,
            position: 'bottom-center',
          }
        );
      })
      .catch((error) => {
        console.log('Erro ao desativar a propriedade: ', error);
        toast.error(
          status === 'active'
            ? 'Erro ao desativar o imóvel'
            : 'Erro ao ativar o imóvel',
          {
            autoClose: 1500,
            position: 'bottom-center',
          }
        );
      })
      .finally(() => {
        console.log('Finalizando a requisição');
        mutate('propertiesList');
      });
  };

  const handleDeleteProperty = (id) => {
    console.log('Deletar propriedade: ', id);
    deleteProperty(id)
      .then(() => {
        console.log('Propriedade deletada com sucesso');
        toast.success('Imóvel excluído com sucesso', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .catch((error) => {
        console.log('Erro ao desativar a propriedade: ', error);
        toast.error('Erro ao excluir o imóvel', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .finally(() => {
        console.log('Finalizando a requisição');
        mutate('propertiesList');
        setIsDeleteDialogOpen(false);
      });
  };

  const handleDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  if (isLoading) {
    return (
      <Backdrop open={isLoading} style={{ zIndex: 99 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  if (isError) {
    return <div>Erro ao carregar os imóveis</div>;
  }

  if (!propertiesList || propertiesList?.length === 0) {
    return (
      <div className='flex flex-col items-center justify-center gap-4 h-full p-6'>
        <MapsHomeWork className='w-16 h-16 ' />
        <h1 className='text-lg md:text-xl lg:text-2xl font-bold'>
          Nenhum imóvel encontrado
        </h1>
        <p className='text-sm md:text-base lg:text-lg text-center'>
          Clique no botão abaixo para adicionar um novo imóvel
        </p>
        <Button
          variant='contained'
          color='success'
          startIcon={<AddHomeWork />}
          onClick={() => navigate('/minhas-propriedades/adicionar')}
        >
          Adicionar Imóvel
        </Button>
      </div>
    );
  }

  return (
    <div className='flex flex-col mt-4 gap-2 items-center px-4'>
      <div>
        <h1 className='text-emerald-400 font-extrabold  text-center text-xl md:text-3xl'>
          Meus Imóveis
        </h1>
      </div>

      <div className='flex my-4'>
        <input
          className='border w-72 border-emerald-700 rounded-md p-2'
          placeholder='Filtre por endereço, descrição ou identificador'
          type='text'
          onChange={handlePropertiesFilter}
        />
      </div>
      <div className='flex w-11/12 xs:w-11/12 sm:w-4/5 md:w-4/5 lg:w-3/6'>
        <h1>{`${propertiesList.length} ${
          propertiesList.length > 1
            ? 'imóveis encontrados'
            : 'imóvel encontrado'
        }`}</h1>
      </div>

      {!isLoading &&
        !isError &&
        propertiesList &&
        propertiesList
          .filter((property) => {
            if (filterText === '') {
              return property;
            } else if (
              property.description
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              property._id.toLowerCase().includes(filterText.toLowerCase()) ||
              property.address.toLowerCase().includes(filterText.toLowerCase())
            ) {
              return property;
            }
          })
          .map((property) => (
            <div
              key={property._id}
              className={`${
                property.status === 'active' ? '' : 'grayscale'
              } w-11/12 xs:w-11/12 sm:w-4/5 md:w-4/5 lg:w-3/6 flex flex-col xs:flex-row  bg-slate-50 hover:bg-emerald-100 shadow-lg border border-emerald-900 rounded hover:scale-101 duration-500 mb-12`}
            >
              <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
              >
                <DialogTitle>Excluir Imóvel</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Você tem certeza que deseja excluir esse imóvel?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    onClick={() => setIsDeleteDialogOpen(false)}
                    className='bg-gray-400 text-white font-bold px-4 py-2 rounded-md hover:bg-gray-500'
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteProperty(property._id);
                    }}
                    className='bg-red-400 text-white font-bold px-4 py-2 rounded-md hover:bg-red-500'
                  >
                    Excluir
                  </button>
                </DialogActions>
              </Dialog>
              <Carousel
                responsive={responsive}
                infinite={true}
                slidesToSlide={1}
                className='w-full xs:w-1/3 h-1/2 xs:h-48'
                swipeable
                draggable
                keyBoardControl={true}
              >
                {[...property.images]
                  .sort((a, b) => {
                    if (a.isCover) return -1;
                    if (b.isCover) return 1;
                  })
                  .map((image) => (
                    <img
                      key={image.name}
                      src={image.url || housePlaceholder}
                      alt='property'
                      className='w-full h-48 object-fill'
                    />
                  ))}
              </Carousel>

              <div className='flex flex-col justify-between pt-2 px-2  w-full xs:w-2/3 gap-1 '>
                <div>
                  <p className='text-base sm:text-lg md:text-xl lg:text-2xl font-bold tracking-tight text-emerald-700 truncate'>
                    {property.description}
                  </p>
                  <p className='font-medium text-sm sm:text-base md:text-lg text-gray-500 truncate'>{`${property.address} - ${property.houseNumber} - ${property.neighborhood}`}</p>
                </div>
                <div className='flex justify-between pr-1 gap-1'>
                  <div className='flex items-center gap-1'>
                    <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${property.area} m²`}</p>
                  </div>
                  <div className='flex items-center gap-1'>
                    <BedOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      property.bedrooms
                    } ${!isLargeDevice ? 'quartos' : ''}`}</p>
                  </div>
                  <div className='flex items-center gap-1'>
                    <ShowerOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      property.bathrooms
                    } ${!isLargeDevice ? 'banheiros' : ''}`}</p>
                  </div>
                  <div className='flex items-center gap-1'>
                    <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      property.parkingSlots
                    } ${!isLargeDevice ? 'vagas' : ''}`}</p>
                  </div>
                </div>
                <div>
                  <p className='text-sm'>{`${
                    property.action === 'sale' ? 'Preço:' : 'Aluguel (R$/mês):'
                  }`}</p>
                  <p className='font-bold'>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(property.price)}
                  </p>
                </div>
                <div className='flex justify-center xs:justify-start gap-4'>
                  <div className='hover:scale-125 duration-200'>
                    <Tooltip title='Ver detalhes' arrow>
                      <IconButton
                        onClick={() => navigate(`/imovel/${property._id}`)}
                      >
                        <Search />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className='hover:scale-125 duration-200'>
                    <Tooltip title='Editar' arrow>
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className='hover:scale-125 duration-200'>
                    <Tooltip
                      title={
                        property.status === 'active' ? 'Desativar' : 'Ativar'
                      }
                      arrow
                    >
                      <IconButton
                        onClick={() =>
                          handleDisableProperty(property._id, property.status)
                        }
                      >
                        {property.status === 'active' ? (
                          <DomainDisabled />
                        ) : (
                          <Domain />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className='hover:scale-125 duration-200'>
                    <Tooltip title='Excluir' arrow>
                      <IconButton onClick={handleDeleteDialog}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          ))}

      <div className='mb-6'>
        <button
          type='button'
          className=' h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
          onClick={() => navigate('/minhas-propriedades/adicionar')}
        >
          <div className='flex gap-4 p-2'>
            <DomainAdd />
            <p>Adicionar Propriedade</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default MyPropertiesPage;
