import {
  Button,
  Divider,
  Drawer,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { Cross1Icon } from '@radix-ui/react-icons';
import { IconButton, Separator } from '@radix-ui/themes';
import React, { useEffect, useState } from 'react';

import logo from '../../media/icons/rentia-logo-512.png';

import LoginForm from '../Layout/LoginForm';
import RegisterForm from '../Layout/RegisterForm';
import MyAccount from '../Layout/MyAccount';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';

const LoginDrawer = ({ loginDrawer, setLoginDrawer }) => {
  const [login, setLogin] = useState(true);
  const { userId } = useSelector((state) => state.loginValues);
  const { loginDrawerOpen } = useSelector((state) => state.drawerValues);
  const dispatch = useDispatch();

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      onOpen={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
      open={loginDrawerOpen}
      onClose={() => dispatch(drawerValuesActions.setLoginDrawer(false))}
      anchor='right'
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <div className='flex flex-col w-80 h-full'>
        <div className='flex justify-between p-4'>
          <img src={logo} alt='Rentia Logo' className='w-8 h-8' />
          <h1 className='font-semibold text-xl '>Rentia</h1>
          <IconButton
            className='hover:cursor-pointer'
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(false))}
          >
            <Cross1Icon />
          </IconButton>
        </div>
        <Divider my='3' size='4' />
        <div className='grow'>
          {userId ? (
            <MyAccount />
          ) : login ? (
            <LoginForm setLogin={setLogin} />
          ) : (
            <RegisterForm setLogin={setLogin} />
          )}
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default LoginDrawer;
