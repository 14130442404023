import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

const CreateAlertDialog = ({
  isCreateAlertDialogOpen,
  handleCloseCreateAlertDialog,
  handleCreateAlert,
}) => {
  return (
    <Dialog
      open={isCreateAlertDialogOpen}
      onClose={handleCloseCreateAlertDialog}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>Criar alerta</DialogTitle>
      <DialogContent dividers={true}>
        <div className='flex flex-col gap-3'>
          <p>
            Ao criar um alerta, você receberá uma notificação neste dispositivo
            sempre que um novo imóvel for anunciado dentro área selecionada.
          </p>
          <p>
            Os filtros aplicados em sua busca serão incluídos ao criar o alerta.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
          onClick={handleCloseCreateAlertDialog}
        >
          Cancelar
        </button>
        <button
          className='bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded'
          onClick={handleCreateAlert}
        >
          Criar
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAlertDialog;
