import {
  BedOutlined,
  DirectionsCarFilledOutlined,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';

const SelectedPropertyMiniCard = ({ propertyItem }) => {
  const { selectedProperty } = useSelector((state) => state.propertyValues);

  if (!selectedProperty) {
    return (
      <div>
        <h3>Nenhum imóvel selecionado</h3>
      </div>
    );
  }

  return (
    <div
      className={`flex shadow-lg w-full select-none border-2 border-gray-400 hover:border-emerald-700 ${
        selectedProperty._id === propertyItem._id
          ? 'bg-emerald-400 bg-opacity-35'
          : 'bg-slate-100'
      } hover:bg-emerald-400 hover:bg-opacity-45 duration-300 cursor-pointer rounded-lg`}
    >
      <div className=''>
        <img
          className='w-28 h-28 object-cover'
          src={propertyItem.images[0].url}
          alt='Imagem de capa do imóvel'
        />
      </div>
      <div className='flex flex-col flex-grow '>
        <div className='flex flex-col items-center justify-center p-2'>
          <p className='text-black text-base font-bold truncate'>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(propertyItem.totalPrice)}
          </p>
        </div>
        <div className='grid grid-cols-2 h-full justify-between gap-1 border-t shadow-lg p-0.5'>
          <div className='flex items-center justify-center gap-1 pl-6 xs:pl-7 lg:pl-8'>
            <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-[10px] md:text-xs text-gray-500 font-medium'>{`${propertyItem.area} m²`}</p>
          </div>
          <div className='flex items-center justify-center gap-1'>
            <BedOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-[10px] md:text-xs text-gray-500 font-medium'>{`${propertyItem.bedrooms}`}</p>
          </div>
          <div className='flex items-center justify-center gap-1'>
            <ShowerOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-[10px] md:text-xs text-gray-500 font-medium'>{`${propertyItem.bathrooms} `}</p>
          </div>
          <div className='flex items-center justify-center gap-1'>
            <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-[10px] md:text-xs text-gray-500 font-medium'>{`${propertyItem.parkingSlots} `}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedPropertyMiniCard;
