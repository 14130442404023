import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

const GeolocationDialog = ({
  isGeolocationDialogOpen,
  handleGeolocationPermission,
}) => {
  return (
    <Dialog
      open={isGeolocationDialogOpen}
      onClose={handleGeolocationPermission}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>Localização</DialogTitle>
      <DialogContent dividers={true}>
        <div className='flex flex-col gap-3'>
          <p>Bem vindo(a) à página de buscas!</p>
          <p>
            Se desejar, você pode permitir o acesso à sua localização para
            melhorar sua experiência ao buscar imóveis.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
          onClick={handleGeolocationPermission}
        >
          Ok
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default GeolocationDialog;
