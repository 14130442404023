import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  selectedMarker: null,
};

const markerValuesSlice = createSlice({
  name: 'markerValues',
  initialState: initialFormState,
  reducers: {
    setSelectedMarker(state, action) {
      state.selectedMarker = action.payload;
    },
  },
});

export const markerValuesActions = markerValuesSlice.actions;

export default markerValuesSlice.reducer;
